<template lang="pug">
.app
  AcceptTermsDialog
  template(v-if="is_production")
    <!-- Ya Metrica inbody part -->
    <noscript><div><img src="https://mc.yandex.ru/watch/74625946" style="position:absolute; left:-9999px;" alt="" /></div></noscript>
  .loading(v-if="loading" v-loading="loading")
  component(:is="layout")
    router-view
</template>

<script>
const default_layout = 'default'

import {mapGetters} from 'vuex'

import AcceptTermsDialog from "@/components/AcceptTermsDialog"

export default {
  components: {
    AcceptTermsDialog,
  },
  metaInfo() {
    const jsSrcs = [
      {src: 'https://kit.fontawesome.com/f0a81f39a8.js', crossorigin: 'anonymous'}, // Font awesome library
      {src: 'https://tag.digitaltarget.ru/adcm.js', async: false} // Amber data processor
    ]

    if (process.env.NODE_ENV !== 'development') {
      jsSrcs.push({src: '/assets/js/ya.js'})
      // jsSrcs.push({src: 'https://www.googletagmanager.com/gtag/js?id=G-THVQEP93ZJ', async: true}) // GA
      // jsSrcs.push({src: '/assets/js/ga.js'}) // GA
    }

    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Opt-in',
      // all titles will be injected into this template
      titleTemplate: 'Сервис управления персональными подписками через смс',
      script: jsSrcs,
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'auth_user',
      'subscriber',
    ]),
    is_production() {
      return process.env.NODE_ENV !== 'development'
    },
    layout() {
      return (this.$route.meta.layout || default_layout) + '-layout'
    },
  },
  created() {
    this.subscriber.boot()
    this.auth_user.boot()

    // Configure Amber data tracking processor on window loaded
    window.addEventListener('load', (event) => {
      if (this.subscriber.loggedIn() && this.subscriber.tracking_uid) {
        const adcm_config = {
          id: 7292,
          platformId: 970,
          profileId: this.subscriber.tracking_uid
        }
        window.adcm.configure(adcm_config)
      }
    })
  },
  methods: {
    addJSSources() {

    },
  },
}
</script>

<style lang="scss">
@import "scss/app";

.app {
  > .loading {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
